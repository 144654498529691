import { faCircleCheck, faCreditCard, faFileLines, faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import {
  faAward,
  faBriefcase,
  faCoins,
  faCube,
  faDisplay,
  faFileSignature,
  faListCheck,
  faRocket,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons'
import { faCreditCardBlank } from 'data/icons/fa-credit-card-blank-regular'
import { faMobileNotch } from 'data/icons/fa-mobile-notch-regular'

export const listIcon = {
  creditCard: faCreditCard,
  list: faListCheck,
  check: faCircleCheck,
  coins: faCoins,
  users: faUserGroup,
  display: faDisplay,
  rocket: faRocket,
  fileLines: faFileLines,
  folderOpen: faFolderOpen,
  fileSignature: faFileSignature,
  briefCase: faBriefcase,
  mobile: faMobileNotch,
  cube: faCube,
  simpleCreditCard: faCreditCardBlank,
  award: faAward
}
