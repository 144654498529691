import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Paragraph } from './Typography'
import styled from '@emotion/styled'
import BACKGROUNDS from 'data/backgrounds'
import Link, { LinkProps } from 'next/link'
import { lightColors } from '@totmoney/colors'
import { mq } from '@totmoney/grid'
import { listIcon } from 'data/list-icons'

export interface AdvantagesCardProps {
  title: string
  icon: keyof typeof listIcon
  linkProps: LinkProps
}

const WrapperCard = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  flexDirection: 'column',
  background: BACKGROUNDS.white,
  boxShadow: '0px 2px 8px 0px #1D1D1B14',
  borderRadius: 8,
  height: '100%',
  padding: '20px 28px',
  p: { transition: '0.4s', textAlign: 'center' },
  '&:hover': {
    boxShadow: '0px 4px 16px 0px #1D1D1B14',
    p: {
      color: '#006ADB'
    },
    '&:active': {
      boxShadow: '0px 4px 24px 0px #1D1D1B14'
    }
  },
  [mq.lg]: {
    padding: '20px 18px'
  }
})

export const AdvantagesCard = ({ title, linkProps, icon }: AdvantagesCardProps) => {
  return (
    <Link {...linkProps}>
      <WrapperCard className='advantages-card'>
        {!!icon && (
          <FontAwesomeIcon
            css={{
              width: '24px',
              height: '24px',
              color: `var(--accent, ${lightColors.accent.base})`
            }}
            icon={listIcon[icon]}
          />
        )}
        {!!title && <Paragraph weight='bold'>{title}</Paragraph>}
      </WrapperCard>
    </Link>
  )
}
