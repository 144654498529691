import { CSSObject } from '@emotion/styled'
import Grid from 'components/Grid'
import { mq } from '@totmoney/grid'
import BACKGROUNDS from 'data/backgrounds'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'
import { TextBlock, TextBlockProps } from './TextBlock'
import Wrapper from './Wrapper'
import { useEffect, useRef, useState } from 'react'
import Image, { ImageProps } from 'next/image'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'

const heroStyles: CSSObject = {
  position: 'relative',
  paddingTop: '4rem'
}
export interface LottieHeroProps extends Omit<TextBlockProps, 'isDisplayText'> {
  lottie: any
  bgColor?: keyof typeof BACKGROUNDS
  lottieVisibility?: { start?: number; end?: number }
  imageTablet?: ImageProps
  imageMobile?: ImageProps
  imageDesktop?: ImageProps
  showLottie?: boolean
}

const LottieHero = ({
  title,
  copy,
  cta,
  lottie,
  label,
  bgColor = 'liliac',
  subtitle,
  lottieVisibility,
  imageTablet,
  imageMobile,
  imageDesktop,
  showLottie = true
}: LottieHeroProps) => {
  const [minHeight, setMinHeight] = useState('100vh')
  const { start = 0.3, end = 0.7 } = lottieVisibility || {}

  const isLargeScreen = useMediaQuery('lg')
  const isTablet = useMediaQuery('xs')

  const getImage = () => {
    if (isLargeScreen) return imageDesktop
    if (isTablet) return imageTablet
    return imageMobile
  }

  const selectedImage = getImage()

  const style = {
    width: '100%',
    height: 'auto',
    maxWidth: 1400,
    marginInline: 'auto'
  }

  const lottieRef = useRef<LottieRefCurrentProps | null>(null)

  useEffect(() => {
    if (!lottieRef.current) return

    setMinHeight('auto')
  }, [])

  return (
    <Wrapper
      css={{ minHeight: showLottie ? minHeight : 'auto' }}
      paddingTop={[0, 0]}
      paddingBottom={[0, 0]}
      bgColor={BACKGROUNDS[bgColor]}
    >
      <div css={heroStyles}>
        <Grid css={{ marginBottom: '4rem', [mq.lg]: { marginBottom: 0 } }}>
          <TextBlock label={label} title={title} copy={copy} cta={cta} subtitle={subtitle} isDisplayText />
        </Grid>
        {showLottie && (
          <Lottie
            lottieRef={lottieRef}
            animationData={lottie}
            style={style}
            autoPlay={false}
            loop={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
            interactivity={{
              mode: 'scroll',
              actions: [
                {
                  visibility: [start, end],
                  type: 'seek',
                  frames: [lottie?.ip, lottie?.op]
                }
              ]
            }}
          />
        )}
        {!!selectedImage?.src && !showLottie && (
          <div
            css={{
              position: 'relative',
              height: 330,
              marginInline: 'auto',
              maxWidth: 1400,
              [mq.xs]: { height: selectedImage.height },
              [mq.md]: { height: '500px' }
            }}
          >
            <Image alt={selectedImage?.alt} src={selectedImage.src} fill />
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default LottieHero
