import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DisplayText, H2, Paragraph } from './Typography'
import styled, { CSSObject } from '@emotion/styled'
import { mq } from '@totmoney/grid'
import { LinkTrackEvent, LinkTrackEventProps } from 'helpers/events/components/LinkTrackEvent'
import { Fragment, ReactNode } from 'react'
import { listIcon } from 'data/list-icons'

export interface TextBlockProps {
  title: string | ReactNode
  copy?: string
  isDisplayText?: boolean
  cta?: LinkTrackEventProps[]
  textAlign?: CSSObject['textAlign']
  subtitle?: string
  label?: string
}

export const TextBlock = ({
  label,
  title,
  copy,
  isDisplayText,
  cta,
  textAlign = 'center',
  subtitle
}: TextBlockProps) => {
  const Component = isDisplayText ? DisplayText : H2

  const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0 })

  const gridColumnStyle = {
    gridColumn: '1 / -1',
    gap: 32,
    [mq.lg]: {
      gridColumn: '2 / -2',
      gap: 40
    },
    [mq.xl]: {
      gridColumn: '3 / -3',
      gap: 40
    }
  }

  const WrapperCta = !!cta?.length && cta?.length > 1 ? 'div' : Fragment
  const WrapperText = subtitle && copy ? 'div' : Fragment
  const WrapperLabelWithCta = !!label ? 'div' : Fragment

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...gridColumnStyle
      }}
    >
      {!!title && (
        <Component {...(isDisplayText && { as: 'h1' })} css={{ textAlign }}>
          {title}
        </Component>
      )}
      <WrapperText>
        {!!subtitle && (
          <Paragraph css={{ textAlign: 'center' }} weight='bold'>
            {subtitle}
          </Paragraph>
        )}
        {!!copy && <Paragraph css={{ textAlign: 'center' }}>{copy}</Paragraph>}
      </WrapperText>
      <WrapperLabelWithCta
        {...(label && { css: { display: 'flex', flexDirection: 'column', gap: 16, alignItems: 'center' } })}
      >
        {!!cta?.length && (
          <WrapperCta {...(cta.length > 1 && { css: { display: 'flex', gap: 24, alignItems: 'center' } })}>
            {cta.map((button, index) => (
              <StyledLinkTrackEvent key={index} {...button}>
                {button.ctaText}
              </StyledLinkTrackEvent>
            ))}
          </WrapperCta>
        )}
        {!!label && (
          <div css={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <FontAwesomeIcon icon={listIcon['award']} />
            <Paragraph css={{ textAlign: 'center' }}>{label}</Paragraph>
          </div>
        )}
      </WrapperLabelWithCta>
    </div>
  )
}
