import { mq } from '@totmoney/grid'
import Grid from 'components/Grid'
import BACKGROUNDS from 'data/backgrounds'
import Wrapper from './Wrapper'
import { TextBlock } from './TextBlock'
import { AdvantagesCard, AdvantagesCardProps } from './AdvantagesCard'

export interface AdvantagesSectionProps {
  subtitle: string
  title: string
  advantages: AdvantagesCardProps[]
}

export const AdvantagesSection = ({ subtitle, title, advantages }: AdvantagesSectionProps) => {
  const gridItemCardStyle = {
    height: '100%',
    gridColumn: 'span 2',
    [mq.xs]: {
      gridColumn: 'span 4'
    },
    [mq.xl]: {
      gridColumn: 'span 2'
    }
  }

  return (
    <Wrapper className='advantages-section' bgColor={BACKGROUNDS['blue']}>
      <Grid css={{ alignItems: 'center', rowGap: '2rem' }}>
        <TextBlock title={title} copy={subtitle} />
      </Grid>
      {!!advantages?.length && (
        <Grid css={{ gridAutoRows: '1fr', marginTop: 64, rowGap: 24, [mq.md]: { rowGap: 44 } }}>
          {advantages.map((advantage) => (
            <div key={advantage.title} css={gridItemCardStyle}>
              <AdvantagesCard {...advantage} />
            </div>
          ))}
        </Grid>
      )}
    </Wrapper>
  )
}
