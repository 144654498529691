import { CharacteristicsSectionProps } from 'components/CharacteristicsSection'
import { BusinessTotSectionProps } from 'components/BusinessTotSection'
import { BusinessManagementSectionProps } from 'components/BusinessManagementSection'
import { AdvantagesSectionProps } from 'components/AdvantagesSection'
import Faq from 'components/Faq'
import { LottieHeroProps } from 'components/LottieHero'
import PreFooter from 'components/PreFooter'
import { TextWithLottieSectionProps } from 'components/TextWithLottieSection'
import { Paragraph } from 'components/Typography'
import lottieHero from 'public/lotties/hero.json'
import lottieMultiutenza from 'public/lotties/multiutenza.json'
import { ComponentProps } from 'react'
import CardRedTotImage from './mock/img/card-red-tot.png'
import CardWhiteTotImage from './mock/img/card-white-tot.png'
import CardsTotImage from './mock/img/cards-tot.png'
import SecurityTotImage from './mock/img/security-tot.png'
import StartUpImage from './mock/img/startup.png'
import TransportImage from './mock/img/transport.png'
import EcommerceImage from './mock/img/ecommerce.png'
import AssociationsImage from './mock/img/associations.png'
import PayWithCardImage from './mock/img/illustrations-scegli.svg'
import ExpenseTrackingImage from './mock/img/illustrations-tienitraccia.svg'
import CardRequestImage from './mock/img/illustrations-richiedi.svg'
import EasyManagementImage from './mock/img/illustrations-gestisci.svg'
import { DiscountSectionProps } from 'components/DiscountSection'
import discount2Image from './mock/img/discount2.png'
import discount3Image from './mock/img/discount3.png'
import teamBusinessManagementImage from './mock/img/team-business-management.svg'
import { htmlStringToDocument } from 'contentful-rich-text-html-parser'
import { MainComponentProps } from 'components/MainComponent'

interface debitCardPageDataProps {
  debitCardHero: LottieHeroProps
  textWithLottieSection: TextWithLottieSectionProps
  advantagesSection: AdvantagesSectionProps
  faq: ComponentProps<typeof Faq>['elements']
  preFooter: ComponentProps<typeof PreFooter>
  characteristicsSection: CharacteristicsSectionProps
  businessTotSection: BusinessTotSectionProps
  businessManagementSection: BusinessManagementSectionProps
  discountSection: DiscountSectionProps
  mainComponentsSection: MainComponentProps
}

export const debitCardPageData: debitCardPageDataProps = {
  debitCardHero: {
    title: 'Carte aziendali per le spese del tuo team',
    copy: 'Pìù flessibilità nella gestione finanziaria, maggiore autonomia per i tuoi collaboratori, più controllo sulle spese aziendali.',
    cta: [
      {
        type: 'button',
        href: 'https://app.tot.money/signup',
        ctaText: 'Apri conto'
      }
    ],
    lottie: lottieHero
  },
  businessManagementSection: {
    title: 'Per tutte le esigenze di spesa del tuo business',
    data: [
      {
        title: 'Prenota tutte le carte aziendali che vuoi',
        copy: 'Un unico conto e tutte le carte aziendali di cui hai bisogno, per ogni esigenza di spesa.',
        image: { src: CardRequestImage, alt: 'icon-tot' }
      },
      {
        title: 'Scegli carte virtuali e fisiche',
        copy: 'Salva le carte nei tuoi wallet digitali e gestisci tutti i pagamenti con facilità e massima sicurezza.',
        image: { src: PayWithCardImage, alt: 'icon-tot' }
      },
      {
        title: 'Gestisci trasferte e acquisti con semplicità',
        copy: 'Più carte per il tuo team, per viaggi, spese carburante, pubblicità o licenze software.',
        image: { src: EasyManagementImage, alt: 'icon-tot' }
      },
      {
        title: 'Controlla e traccia tutte le spese',
        copy: 'Assegna un nome ad ogni carta, imposta il limite di spesa e controlla le transazioni in real time.',
        image: { src: ExpenseTrackingImage, alt: 'icon-tot' }
      }
    ]
  },
  textWithLottieSection: {
    imageFirst: false,
    links: [
      {
        label: 'Apri conto',
        url: 'https://app.tot.money/signup'
      }
    ],
    subtitle: 'Pagamenti con carte aziendali',
    text: (
      <>
        <Paragraph>
          Con le carte aziendali Tot, non ti preoccupi più dei rimborsi e scarichi pure l’IVA da tutte le spese
          aziendali, anche quelle che fanno i tuoi dipendenti.
        </Paragraph>
        <Paragraph>
          E poi i tuoi collaboratori sono più autonomi e anche più soddisfatti, perché non devono anticipare niente di
          tasca propria.
        </Paragraph>
      </>
    ),
    title: 'Basta preoccuparsi dei rimborsi spese',
    lottie: lottieMultiutenza
  },
  characteristicsSection: {
    title: 'L’universo delle carte aziendali Tot',
    copy: 'Tot è il conto aziendale che ti aiuta a semplificare sin da subito la gestione finanziaria e contabile.',
    data: [
      {
        title: 'Tutte le nostre carte business',
        text: [
          <>
            <Paragraph>
              Con il conto aziendale Tot, hai inclusa una{' '}
              <span style={{ fontWeight: 'bold' }}>carta Visa business</span> a cui puoi aggiungere tutte{' '}
              <span style={{ fontWeight: 'bold' }}>le carte di debito aziendali</span> di cui hai bisogno (prenotabili).
            </Paragraph>
          </>,
          <>
            <Paragraph>
              Attiva nuove carte, <span style={{ fontWeight: 'bold' }}>imposta un budget per ognuna,</span> monitora e
              ottimizza tutte le spese aziendali in tempo reale direttamente dall’app.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              Inoltre, puoi aggiungere tutte le carte aziendali ai tuoi{' '}
              <span style={{ fontWeight: 'bold' }}>wallet digitali su Apple e Google Pay</span> per acquisti online o
              contactless in milioni di negozi, in modo ancora più sicuro e smart.
            </Paragraph>
          </>
        ],

        image: { src: CardsTotImage, alt: 'illustration-tot' }
      },
      {
        title: 'Carte di debito virtuali',
        text: [
          <>
            <Paragraph>Tutte le carte virtuali Tot sono debit Mastercard.</Paragraph>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Emissione immediata:</span> prenota tutte le carte che vuoi e le hai
              subito a disposizione per le tue spese aziendali.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Perfette per l’online:</span> gestisci i tuoi abbonamenti online e
              acquista su e-commerce con la massima sicurezza e semplicità.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Ancora più sicure:</span> le carte virtuali non possono essere
              smarrite o rubate e le puoi attivare o disattivare in base alle tue necessità.
            </Paragraph>
          </>
        ],
        image: { src: CardRedTotImage, alt: 'illustration-tot' },
        label: 'Disponibili a breve'
      },
      {
        title: 'Carte di debito fisiche',
        text: [
          <>
            <Paragraph>Tutte le carte fisiche Tot sono debit Mastercard.</Paragraph>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Perfette per chi lavora sul campo:</span> viaggia, preleva e fai
              acquisti in tutto il mondo. La soluzione migliore per te e i tuoi collaboratori.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Limiti di spesa elevati:</span> fino a 20.000€ per i pagamenti con il
              POS e fino a 3.000€ di prelievo al mese, così gestisci trasferte e spese aziendali senza preoccuparti dei
              limiti.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              <span style={{ fontWeight: 'bold' }}>Rimborso in caso di furti:</span> con le carte Tot hai sempre il
              chargeback in caso di acquisti fraudolenti o non autorizzati.
            </Paragraph>
          </>
        ],

        image: { src: CardWhiteTotImage, alt: 'illustration-tot' },
        label: 'Disponibili a breve'
      },
      {
        title: 'Massima sicurezza sui pagamenti',
        text: [
          <>
            <Paragraph>
              Con Tot hai la massima sicurezza per i tuoi acquisti aziendali, sia fisici che online, grazie alle
              tecnologie più moderne di verifica dell’identità. In più, in caso di frodi o acquisti non autorizzati, le
              nostre carte business ti proteggono sempre con il <span style={{ fontWeight: 'bold' }}>chargeback.</span>
            </Paragraph>
          </>,
          <>
            <Paragraph>
              Le <span style={{ fontWeight: 'bold' }}>Mastercard debit sono accettate ovunque nel mondo,</span> ma se
              preferisci puoi sempre prelevare in contanti.
            </Paragraph>
          </>,
          <>
            <Paragraph>
              Non ti servono più? Nessun problema, puoi sospenderle o disabilitarle definitivamente dal tuo account.
            </Paragraph>
          </>
        ],
        image: { src: SecurityTotImage, alt: 'illustration-tot' }
      }
    ]
  },
  mainComponentsSection: {
    imageFirst: true,
    subtitle: 'Ruoli e accessi per il team',
    title: 'Gestire un business è un gioco di squadra',
    text: (
      <>
        <Paragraph>Non solo carte per i tuoi collaboratori.</Paragraph>
        <Paragraph>
          <span style={{ fontWeight: 'bold' }}>Dai accesso al conto a più membri del tuo team</span> e fatti aiutare in
          tutta la gestione amministrativa del tuo business o nel predisporre i vari pagamenti ai fornitori, che poi
          devi solo controllare e approvare.
        </Paragraph>
      </>
    ),
    links: [{ url: '/amministrazione', label: 'Scopri di più' }],
    image: { url: teamBusinessManagementImage, description: 'image-tot' },
    name: 'Gestire un business è un gioco di squadra'
  },
  discountSection: {
    title: 'Aggiungi al tuo conto business tutte le carte che vuoi',
    copy: 'Scegli il piano migliore per te, subito inclusa una carta Visa Business. In più non hai limiti alle carte di debito che puoi aggiungere. Prenotale subito!',
    cta: { label: 'Scopri di più', url: '/prezzi' },
    data: [
      {
        discountType: 'Plus',
        price: '15',
        image: { src: discount2Image, alt: 'image-tot' },
        cta: {
          ctaText: 'Apri conto',
          type: 'button',
          href: 'https://app.tot.money/signup'
        },
        copy: 'Prima carta Visa inclusa, ulteriori carte a pagamento.'
      },
      {
        discountType: 'Premium',
        price: '49',
        discountPrice: '32',
        image: { src: discount3Image, alt: 'image-tot' },
        cta: {
          ctaText: 'Apri conto',
          type: 'button',
          href: 'https://app.tot.money/signup'
        },
        copy: 'Prima carta Visa inclusa, ulteriori carte a pagamento.'
      }
    ]
  },
  advantagesSection: {
    title: 'Tutti i vantaggi di Tot',
    subtitle:
      'Scopri come migliorare la gestione delle spese della tua azienda e quali servizi ti mettiamo a disposizione',
    advantages: [
      {
        title: 'Conto aziendale',
        linkProps: { href: '/conto-aziendale' },
        icon: 'coins'
      },
      {
        title: 'Carta di credito',
        linkProps: { href: '/carta-di-credito-aziendale' },
        icon: 'creditCard'
      },
      {
        title: 'Bonifici istantanei',
        linkProps: { href: '/bonifici' },
        icon: 'rocket'
      },
      {
        title: 'F24',
        linkProps: { href: '/f24' },
        icon: 'fileLines'
      },
      {
        title: 'RiBA',
        linkProps: { href: '/riba' },
        icon: 'folderOpen'
      },
      {
        title: 'Nuove aziende',
        linkProps: { href: '/nuove-aziende' },
        icon: 'briefCase'
      }
    ]
  },
  faq: [
    {
      answer: {
        json: htmlStringToDocument(`
          <p>
          Con Tot <strong>puoi aggiungere tutte le carte aziendali di cui hai bisogno,</strong> per te o da mettere a disposizione del tuo team.
          Inoltre <strong>puoi attivare o sospendere una carta con un semplice click,</strong> completamente online, senza fare code in banca o attese infinite al telefono.
          <strong>Non ci sono limiti al numero di carte business che puoi avere , sia fisiche che virtuali.</strong>
          </p>
       `)
      },
      question: 'Quante carte aziendali posso avere collegate al mio conto business?'
    },
    {
      answer: {
        json: htmlStringToDocument(`<p>Qualsiasi sia il piano di abbonamento scelto, con Tot hai inclusa una <strong>carta di credito aziendale</strong> senza plafond, collegata al saldo del tuo conto.
        Per una gestione ancora più agile e flessibile del tuo business, Tot ti dà la possibilità di <strong>aggiungere illimitate carte di debito aziendali.</strong></p>`)
      },
      question: 'Le carte aziendali di Tot sono carte di debito o di credito?'
    },
    {
      answer: {
        json: htmlStringToDocument(`
      <p>Con Tot la <strong>carta Visa Business</strong> (che funziona sul circuito di credito) <strong>è inclusa e gratuita.</strong>
      Puoi inoltre richiedere illimitate carte di debito business: per queste il canone è di <strong>1€ al mese</strong> per singola carta virtuale e di <strong>5€ al mese</strong> per singola carta fisica.</p>`)
      },
      question: 'Le carte aziendali di Tot sono gratuite?'
    },
    {
      answer: {
        json: htmlStringToDocument(`
        <strong>I vantaggi della carta di credito aziendale Tot:</strong>
        <ul>
        <li>Accettata ovunque nel mondo</li>
        <li>Consente di noleggiare auto, prenotare alberghi e altri servizi</li>
        <li>Assicurazione contro i furti inclusa</li>
        <li>Movimenti visualizzabili in real time dalla dashboard</li>
        </ul>
        <strong>I vantaggi delle carte di debito aziendali Tot?</strong>
        <ul>
        <li>Limiti di spesa elevati (20.000€ con il POS e 3.000€ di prelievo, al mese)</li>
        <li>Possibilità di impostare un budget di spesa per ogni carta</li>
        <li>Rimborso in caso di acquisti non autorizzati</li>
        <li>Transazioni monitorabili in tempo reale</li>
        </ul>
     `)
      },
      question: 'Quali sono i vantaggi delle carte business di Tot?'
    },
    {
      answer: {
        json: htmlStringToDocument(`
      <p>No, <strong>le carte aziendali Tot sono tutte carte di debito,</strong> eccetto quella inclusa all’apertura del conto che è una carta che funziona sul circuito di credito.
      Tutte le carte di debito sono collegate ad un unico wallet all’interno del tuo conto aziendale Tot. Per utilizzarle, dunque, dovrai ricaricare il wallet attraverso bonifico istantaneo. Così, decidi tu il budget da assegnare alle tue carte di debito e il tuo denaro è ancora più al sicuro.</p>
      <p><strong>La ricarica del wallet non ha alcun costo e non è conteggiato all’interno delle operazioni incluse nel tuo piano d’abbonamento.</strong></p>`)
      },
      question: 'Le carte business di Tot sono prepagate?'
    }
  ],
  preFooter: {
    cta: 'Provalo Gratis',
    url: 'https://app.tot.money/signup',
    text: 'Il conto aziendale online più potente mai realizzato da una fintech 100% italiana. Conto con IBAN italiano, bonifici istantanei senza commissioni, carte business, pagoPA, F24 con e senza delega.',
    title: 'Apri ora il conto Tot'
  },
  businessTotSection: {
    title: 'Il conto business perfetto per...',
    data: [
      {
        title: 'Startup e PMI',
        copy: 'Semplifica fin dal giorno zero tutta la gestione finanziaria e contabile della tua nuova azienda. Il conto perfetto per aziende smart e innovative.',
        image: { src: StartUpImage, alt: 'start-up-image' },
        url: '/nuove-aziende'
      },
      {
        title: 'E-commerce',
        copy: 'Gestisci incassi e pagamenti completamente online, importa tutte le fatture senza mai uscire dal tuo conto e conservale in perfetto ordine.',
        image: { src: EcommerceImage, alt: 'e-commerce-image' },
        url: '/e-commerce'
      },
      {
        title: 'Logistica e trasporti',
        copy: 'Ottieni fino a 700 operazioni in uscita incluse senza costi di commissione, compresi i bonifici istantanei. Le operazioni in entrata sono sempre gratuite.',
        image: { src: TransportImage, alt: 'transport-image' },
        url: '/logistica-trasporti'
      },
      {
        title: 'Associazioni',
        copy: 'Richiedi il conto in 10 minuti e hai subito una carta VISA Business, pagamenti F24, bonifici istantanei, pagoPA, tutti inclusi, senza commissioni.',
        image: { src: AssociationsImage, alt: 'associations-image' },
        url: '/associazioni'
      }
    ]
  }
}
