import { H3, Label, RichText } from './Typography'
import styled from '@emotion/styled'
import { mq } from '@totmoney/grid'
import BACKGROUNDS from 'data/backgrounds'
import Image, { ImageProps } from 'next/image'
import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSparkles } from 'data/icons/fa-sparkles-solid'
import { useMediaQuery } from 'helpers/hooks/useMediaQuery'
import { Accordion } from '@totmoney/accordion'

export interface SliderCardProps {
  title: string
  text: ReactNode | ReactNode[]
  image: ImageProps
  label?: string
  accordionProps?: { open: boolean; onChange: () => void }
}

const WrapperCard = styled.div({
  display: 'flex',
  gap: 24,
  background: BACKGROUNDS.white,
  boxShadow: '0px 4px 24px 0px #1D1D1B14',
  borderRadius: 8,
  padding: 24,
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  [mq.xs]: { padding: 40 },
  [mq.lg]: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  [mq.xl]: {
    padding: '53px 40px'
  }
})

const WrapperImage = styled.div({
  position: 'relative',
  borderRadius: 8,
  width: '100%',
  height: 200,
  overflow: 'hidden',
  marginTop: 'auto',
  [mq.xs]: {
    marginTop: 'unset',
    height: 320
  },
  [mq.lg]: { maxWidth: '50%' },
  [mq.xl]: { maxWidth: 444 }
})

const WrapperLabel = styled.div({
  position: 'relative',
  borderRadius: 8,
  padding: '4px 8px',
  width: 'max-content'
})

const WrapperTitle = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  [mq.xs]: {
    flexDirection: 'row'
  }
})

const accordionStyle = {
  button: {
    background: BACKGROUNDS['blue'],
    padding: 8,
    marginTop: 32,
    span: {
      display: 'flex',
      justifyContent: 'center',
      color: '#006ADB',
      span: { marginLeft: 12 }
    }
  },
  'button[aria-expanded="false"]': {
    span: {
      'p::before': {
        content: '"Espandi"'
      }
    }
  },
  'button[aria-expanded="true"]': {
    span: {
      'p::before': {
        content: '"Chiaro, ora chiudi"'
      }
    }
  }
}

export const SliderCard = ({ title, text, image, label, accordionProps }: SliderCardProps) => {
  const isLargeScreen = useMediaQuery('xs')

  const contentText = () => {
    if (Array.isArray(text)) {
      const { 0: firstElement, ...restText } = text || {}
      return { firstElement, restText }
    }

    return { firstElement: text }
  }

  const restText = contentText().restText
  const isEmptyObject = restText ? Object.keys(restText).length === 0 : true

  return (
    <WrapperCard className='slider-card'>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 32,
          [mq.xs]: { alignSelf: 'baseline', gap: 40 },
          [mq.lg]: { width: '50%', marginRight: 'auto' }
        }}
      >
        <WrapperTitle>
          {!!title && <H3>{title}</H3>}
          {!!label && (
            <WrapperLabel
              style={{
                height: 'max-content',
                backgroundColor: '#F2EBFF',
                whiteSpace: 'nowrap',
                alignSelf: 'flex-start'
              }}
            >
              <Label size='s' style={{ color: '#4700CC' }}>
                {label}
              </Label>
              <FontAwesomeIcon
                icon={faSparkles}
                color='#4700CC'
                style={{ position: 'absolute', top: '-0.5rem', right: '-0.5rem' }}
              />
            </WrapperLabel>
          )}
        </WrapperTitle>
        {!!text && (
          <>
            {isLargeScreen ? (
              <RichText>{text}</RichText>
            ) : (
              <div>
                <RichText>{contentText().firstElement}</RichText>
                {!isEmptyObject && (
                  <Accordion css={accordionStyle} {...(accordionProps && accordionProps)}>
                    <Accordion.Content>
                      <RichText>
                        {Object.keys(restText || {}).map((_, index) => (
                          <div key={index}>{restText?.[index + 1]}</div>
                        ))}
                      </RichText>
                    </Accordion.Content>
                    <Accordion.Button>
                      <Label as='p' size='m' css={{ textAlign: 'left' }}></Label>
                    </Accordion.Button>
                  </Accordion>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {!!image.src && (
        <WrapperImage>
          <Image css={{ objectFit: 'contain' }} fill sizes='(min-width: 1024px): 41,67vw, 100vw' {...image} />
        </WrapperImage>
      )}
    </WrapperCard>
  )
}
