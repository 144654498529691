import Grid from 'components/Grid'
import BACKGROUNDS from 'data/backgrounds'
import Wrapper from './Wrapper'
import { TextBlock } from './TextBlock'
import { DiscountCard, DiscountCardProps } from './DiscountCard'
import { mq } from '@totmoney/grid'
import { LinkTrackEvent } from 'helpers/events/components/LinkTrackEvent'
import { LinkInfo } from './LinkComponent'
import { CSSObject } from '@emotion/react'
import { ReactNode } from 'react'
export interface DiscountSectionProps {
  title: string | ReactNode
  copy?: string
  cta?: LinkInfo
  data: DiscountCardProps[]
  containerCardStyle?: CSSObject
}

export const DiscountSection = ({ cta, title, copy, data, containerCardStyle }: DiscountSectionProps) => {
  return (
    <Wrapper className='discount-section' bgColor={BACKGROUNDS['white']}>
      <Grid>
        <TextBlock title={title} copy={copy} />
        {!!data?.length && (
          <div
            css={{
              marginTop: 40,
              gridColumn: '1/-1',
              display: 'grid',
              columnGap: 24,
              rowGap: 24,
              [mq.xs]: { gridTemplateColumns: 'repeat(2, 1fr)' },
              [mq.lg]: { gridColumn: '2/-2' },
              [mq.xl]: { gridColumn: '3/-3' },
              ...containerCardStyle
            }}
          >
            {data.map((element, index) => (
              <DiscountCard key={index} {...element} />
            ))}
          </div>
        )}
      </Grid>
      {!!cta?.url && (
        <div css={{ marginTop: 40, marginInline: 'auto', width: 'max-content' }}>
          <LinkTrackEvent type='linkWithIcon' href={cta.url} label={cta.label} icon='link' {...cta} />
        </div>
      )}
    </Wrapper>
  )
}
