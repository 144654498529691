import { Caption, H1, H4, Paragraph } from './Typography'
import styled, { CSSObject } from '@emotion/styled'
import { mq } from '@totmoney/grid'
import Image, { ImageProps } from 'next/image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { LinkTrackEvent, LinkTrackEventProps } from 'helpers/events/components/LinkTrackEvent'
import { useEffect, useRef, useState } from 'react'

export interface DiscountCardProps {
  discountType: string
  price: string
  discountPrice?: string
  image: ImageProps
  cta: LinkTrackEventProps
  copy: string
}

export const DiscountCard = ({ discountType, price, discountPrice, cta, image, copy }: DiscountCardProps) => {
  const [totalHeight, setTotalHeight] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const StyledLinkTrackEvent = styled(LinkTrackEvent)({ margin: 0, whiteSpace: 'nowrap', width: 'max-content' })

  const WrapperCard: CSSObject = {
    borderRadius: 8,
    minHeight: 360,
    background: '#DFF2FF',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    '.wrapper-text': {
      position: 'relative',
      zIndex: 1,
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    [mq.lg]: {
      minHeight: 281,
      '.wrapper-text': {
        pading: 24,
        bottom: -totalHeight,
        '.wrapper-copy': { transition: '0.4s', opacity: 0 },
        transition: '0.4s'
      },
      '.wrapper-image': {
        img: {
          transition: '0.4s',
          opacity: 1
        }
      },
      '&:hover': {
        '.wrapper-text': { bottom: 0, '.wrapper-copy': { opacity: 1 } },
        '.wrapper-image': {
          img: {
            opacity: 0
          }
        }
      }
    }
  }

  const wrapperImageStyle: CSSObject = {
    zIndex: 0,
    position: 'absolute',
    height: '100%',
    right: -17
  }

  useEffect(() => {
    if (containerRef.current) {
      const marginTop = window.getComputedStyle(containerRef.current).marginTop
      const height = containerRef?.current.offsetHeight
      const calcTotalHeight = Number(marginTop.split('px')[0]) + height
      setTotalHeight(calcTotalHeight)
    }
  }, [])

  return (
    <div className='discount-card' css={WrapperCard}>
      {!!image.src && (
        <div className='wrapper-image' css={wrapperImageStyle}>
          <div style={{ position: 'relative', height: '100%', width: 148 }}>
            <Image style={{ objectFit: 'contain', objectPosition: 'bottom' }} fill src={image.src} alt={image.alt} />
          </div>{' '}
        </div>
      )}
      <div className='wrapper-text'>
        <div className='container-text'>
          {!!discountType && <H4>{discountType}</H4>}{' '}
          <div css={{ display: 'flex', gap: 4, marginTop: 8, paddingBottom: 4 }}>
            {!!price && <H1>{discountPrice ?? price} €</H1>}
            {!!discountPrice && (
              <Paragraph css={{ alignSelf: 'end', textDecoration: 'line-through', fontSize: 24 }}>{price}€</Paragraph>
            )}
          </div>
          <Caption>/mese + IVA</Caption>
        </div>
        <div
          className='wrapper-copy'
          ref={containerRef}
          css={{
            marginTop: 'auto',
            display: 'flex',
            flexDirection: 'column',
            [mq.lg]: { marginTop: 'auto', justifyContent: 'reverse', flexDirection: 'row' }
          }}
        >
          {!!copy && (
            <div
              css={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                [mq.lg]: {
                  gap: 12,
                  alignItems: 'end',
                  flexDirection: 'row-reverse',
                  justifyContent: 'center'
                }
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  [mq.lg]: {
                    flexDirection: 'row',
                    gap: 8,
                    alignItems: 'center'
                  }
                }}
              >
                <div
                  css={{
                    minWidth: 36,
                    width: 36,
                    minHeight: 36,
                    background: ' #9FD9FF',
                    borderRadius: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <FontAwesomeIcon icon={faCreditCard} color='#191919' />
                </div>
                <Caption
                  css={{
                    display: 'block',
                    width: '55%',
                    marginBottom: 16,
                    [mq.lg]: { width: 'auto', marginBottom: 0 }
                  }}
                >
                  {copy}
                </Caption>
              </div>
              {!!cta && <StyledLinkTrackEvent {...cta}>{cta.ctaText}</StyledLinkTrackEvent>}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
