import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@totmoney/button'
import useEmblaCarousel from 'embla-carousel-react'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import Container from './Container'

export interface CarouselProps {
  content: Record<string, any>[]
  options?: Record<string, unknown>
  sizeSlide?: string
  slideMapper: (props: { slide: any; index: number }) => JSX.Element
  containerStyle?: CSSProperties
  sliderContainerStyle?: CSSProperties
  isOverflowed?: boolean
  wrapperStyle?: CSSProperties
  buttonsStyle?: CSSProperties
  nextSlideButtonProps?: () => void
  prevSlideButtonProps?: () => void
}

export const CarouselWrapper = ({
  content,
  options,
  sizeSlide = '0 0 100%',
  slideMapper,
  containerStyle,
  sliderContainerStyle,
  isOverflowed = false,
  wrapperStyle,
  buttonsStyle,
  nextSlideButtonProps,
  prevSlideButtonProps
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const [slide, setSlide] = useState(0)

  const getCurrentSlide = useCallback(() => {
    if (!emblaApi) return
    setSlide(emblaApi.selectedScrollSnap())
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    emblaApi.on('select', getCurrentSlide)

    return () => {
      emblaApi.off('select', getCurrentSlide)
    }
  }, [emblaApi, getCurrentSlide])

  const next = useCallback(() => {
    nextSlideButtonProps && nextSlideButtonProps()
    emblaApi && emblaApi.scrollNext()
  }, [emblaApi])

  const prev = useCallback(() => {
    prevSlideButtonProps && prevSlideButtonProps()
    emblaApi && emblaApi.scrollPrev()
  }, [emblaApi])

  const styleButtons = {
    padding: '12px 24px',
    display: 'flex',
    alignContent: 'center'
  }
  const CarouselWrapper = isOverflowed ? 'div' : Container
  const defaultPadding = isOverflowed && 24
  return (
    <>
      <CarouselWrapper
        className='carousel-wrapper'
        ref={emblaRef}
        style={{ overflow: 'hidden', ...(defaultPadding && { paddingInline: defaultPadding }), ...wrapperStyle }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            paddingBlock: 32,
            ...containerStyle
          }}
        >
          {content?.map((slide, index) => (
            <div
              key={index}
              css={{
                flex: sizeSlide,
                ...sliderContainerStyle
              }}
            >
              {slideMapper({
                slide: { index, ...slide },
                index
              })}
            </div>
          ))}
        </div>
      </CarouselWrapper>
      <div
        className='buttons-carousel-wrapper'
        style={{
          display: 'flex',
          gap: 24,
          justifyContent: 'center',
          ...buttonsStyle
        }}
      >
        <Button style={styleButtons} onClick={prev} disabled={slide === 0}>
          <FontAwesomeIcon icon={faChevronLeft} style={{ width: 24, height: 24, position: 'relative', top: -3 }} />
        </Button>
        <Button style={styleButtons} onClick={next} disabled={slide === content.length - 1}>
          <FontAwesomeIcon icon={faChevronRight} style={{ width: 24, height: 24, position: 'relative', top: -3 }} />
        </Button>
      </div>
    </>
  )
}
